<template>
    <div :class="containerClasses">
        <div class="my-4 rounded-lg p-6 border border-3 rounded-lg flex-1">
            <div class="flex flex-wrap items-center mt-2">
                <div class="w-full lg:w-2/5 relative">
                    <div class="absolute left-0 -top-2 flex flex-wrap items-center justify-start" id="discount-badge">
                        <div class="bg-kbdvred rounded-full text-xs px-2 py-0.5 text-white font-santralregular font-semibold leading-4 mt-2 mr-2" v-if="discountRate > 0">%{{ discountRate }} indirim</div>
                        <div class="bg-kbgreen rounded-full text-xs px-2 py-0.5 text-white font-santralregular font-semibold leading-4 mt-2 mr-2" v-if="product.tags && product.tags.filter((x) => x.id == 34).length == 1">Yeni</div>
                        <div class="bg-black rounded-full text-xs px-2 py-0.5 text-white font-santralregular font-semibold leading-4 mt-2 mr-2" v-if="product.tags && product.tags.filter((x) => x.id == 35).length == 1">Kirala + Satın al</div>
                        <div class="bg-kbgray rounded-full text-xs px-2 py-0.5 text-white font-santralregular font-semibold leading-4 mt-2 mr-2" v-if="product.tags && product.tags.filter((x) => x.id == 33).length == 1">Ön Sipariş</div>
                    </div>
                    <Link :href="`/urun/${product.default_url?.slug}`">
                        <picture>
                            <source :srcset="product.imagesOptimized[0]?.urlThumbWebP" type="image/webp" />
                            <source :srcset="product.imagesOptimized[0]?.urlThumbOrj" type="image/jpeg" />
                            <img :src="product.imagesOptimized[0]?.urlThumbOrj" alt="Alt Text!" />
                        </picture>
                    </Link>
                </div>
                <div class="md:pl-4 w-full lg:w-3/5">
                    <div class="text-2xl font-semibold mt-3">
                        <Link :href="`/urun/${product.default_url?.slug}`" v-html="product.attribute_data.name.tr"></Link>
                    </div>
                    <div class="text-sm mt-1 text-kbgray">
                        <Link :href="`/urun/${product.default_url?.slug}`" v-html="product.attribute_data.excerpt.tr"></Link>
                    </div>
                    <div class="mt-3 max-w-smv-p-box lg:max-w-v-p-box">
                        <div class="flex text-xs text-center cursor-pointer">
                            <ul class="flex border rounded-full bg-white w-full items-center justify-center shadow-xl h-8 ts:h-12">
                                <template v-for="item in subscribetionMonthsOrdered">
                                    <li class="text-textgray whitespace-nowrap w-16 h-14 text-xs hover:scale-125 transform duration-300 ease-in-out items-center justify-center flex"
                                        :class="[selectedMonth == item[1].subscription_months.id ? selectedClass : '']" @click="selectMonth(item[1].subscription_months.id)" v-if="item[1].price">
                                        {{ item[1].subscription_months?.name }}
                                    </li>
                                </template>
                                <!--                                                <li v-for="(value,key) in item.price" :key="key" class="text-textgray whitespace-nowrap w-16 h-14 text-xs hover:scale-125 transform duration-300 ease-in-out items-center justify-center flex" :class="[ item.selectedMonth == key ? selectedClass : '' ]" @click="selectMonth(key, item)" v-html="key+' Ay'"></li>-->
                            </ul>
                        </div>
                    </div>
                    <div class="flex mt-2">
                        <span class="font-semibold text-2xl mt-2"><Link :href="`/urun/${product.default_url?.slug}`">{{ price }} TL/</Link></span>
                        <span class="text-xs self-center ml-2 font-santralregular"><Link :href="`/urun/${product.default_url?.slug}`">Aylık ödenecek tutar</Link></span>
                    </div>
                    <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center">
                        <Link :href="`/urun/${product.default_url?.slug}`"> Görüntüle</Link>
                    </button>
                </div>
            </div>
        </div>

    </div>


</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import TextClamp from "vue3-text-clamp";

export default {
    data() {
        return {
            selectedMonth: null,
            selectedClass: " shadow-inner shadow-sm border-2 h-14 md:h-14 ts:h-16 rounded-full border-kbgreen p-2 lg:p-4 items-center justify-center flex bg-white !text-black text-xs hover:!scale-100",
            price: 0,
            comparePrice: 0,
            discountRate: 0,
            favouriteSate: false,
            containerClasses: "w-full mts:w-6/12 lg:w-4/12",
            subscribetionMonthsOrdered: [],
            summerBadge: true
        };
    },
    components: {
        Link,
        TextClamp
    },
    props: {
        product: Object,
        auth: Object,
        newContainerClasses: {
            type: String,
            value: null
        }
    },
    methods: {
        selectMonth(month) {
            this.selectedMonth = month;
            this.price = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months.id == month).price / 100;
            this.comparePrice = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months.id == month).compare_price / 100;
            if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
            if (this.comparePrice == 0) this.discountRate = null;
        },
        toogleFavourite() {
            // Kullanıcı login değil ise favori eklemek için login olmalı, yönlendir
            if (this.auth.token == null) {
                this.$inertia.get("/giris-yap");
            }

            window.axios.defaults.headers.common["Authorization"] = `Bearer ${this.auth.token}`;
            axios
                .post(`/favourite/${this.product.id}/toogle`, {
                    productSlug: this.product.default_url.slug
                })
                .then((res) => {
                    if (res.data.status == "created") {
                        this.favouriteSate = true;
                    } else {
                        this.favouriteSate = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clickProduct() {
            return;
            // Bu satırsan sonrası neden var?
            let cartProducts = [];

            let subscribetionMonthsOrdered = [];
            if (this.product.variants[0]?.prices.length > 0) subscribetionMonthsOrdered = Object.entries(this.product.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
            let nonZeroPrices = this.product.variants[0]?.prices.filter((price) => price.price > 0);
            subscribetionMonthsOrdered = subscribetionMonthsOrdered.filter((price) => price[1].price > 0);
            let productPrice = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price / 100;

            let product = {};
            product.item_id = this.product.id;
            product.item_name = this.product.attribute_data.name.tr;
            product.price = productPrice;
            product.item_brand = this.product.brand.name;
            product.item_category = this.product.collections[0]?.attribute_data?.name?.tr;
            product.item_category2 = this.product.collections[1]?.attribute_data?.name?.tr ?? "";
            // product.item_comments = item.reviews.total;
            // product.item_rating = item.id;
            // (product.item_color = item.variants[0]?.options[0] ?? ""), (product.index = 0);
            product.discount = 0;
            product.item_variant = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.subscription_months?.name;
            cartProducts.push(product);

            console.log(cartProducts);
            dataLayer.push({
                event: "select_item",
                ecommerce: {
                    item_list_id: "HOME-1",
                    item_list_name: "Homepage - Kampanya Ürünleri List",
                    items: cartProducts
                }
            });
        }
    },
    computed: {
        badgeControl: function() {
            let categoryIds = [97, 96];
            return this.product.collections.filter(collection => categoryIds.includes(collection.id)).length === 0;
        }
    },
    mounted() {
        if (this.product?.variants[0]?.prices.length > 0) this.subscribetionMonthsOrdered = Object.entries(this.product?.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
        this.selectedVariandId = this.product.variants[0].id;
        let nonZeroPrices = this.product.variants[0].prices.filter((price) => price.price > 0);
        this.subscribetionMonthsOrdered = this.subscribetionMonthsOrdered.filter((price) => price[1].price > 0);
        this.price = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price / 100;
        this.comparePrice = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.compare_price / 100;
        if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
        if (this.comparePrice == 0) this.discountRate = null;

        this.selectedMonth = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.subscription_months?.id;
        this.favouriteSate = this.product.favourite;
        if (this.newContainerClasses != undefined) {
            this.containerClasses = this.newContainerClasses;
        }
    }
};
</script>

<style scoped></style>
